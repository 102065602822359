<template>
  <div>
    <el-form ref="baseformref" :model="baseform" label-width="180px">
      <el-form-item :label="$t('cs.wzmc')" prop="sitename">
        <el-input v-model="baseform.sitename"></el-input>
      </el-form-item>
      <el-form-item :label="$t('logo')" prop="imageurl">
        <el-upload
          class="avatar-uploader"
          :action="weburl + '/admin/wallet/uploadimg'"
          :show-file-list="false"
          :headers="headerobj"
          :on-success="upload"
        >
          <img
            v-if="baseform.imageurl"
            :src="baseform.imageurl"
            class="avatar"
          />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('cs.kfdz')" prop="service_url">
        <el-input v-model="baseform.service_url"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.lxmjdz')" prop="sellurl">
        <el-input v-model="baseform.sellurl" type="number"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao')" prop="notice">
        <el-input type="textarea" v-model="baseform.notice"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(美国)'" prop="notice">
        <el-input type="textarea" v-model="baseform.notice_en"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(西班牙)'" prop="notice">
        <el-input type="textarea" v-model="baseform.notice_es"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(法国)'" prop="notice_fr">
        <el-input type="textarea" v-model="baseform.notice_fr"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(意大利)'" prop="notice_it">
        <el-input type="textarea" v-model="baseform.notice_it"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(俄罗斯)'" prop="notice_ru">
        <el-input type="textarea" v-model="baseform.notice_ru"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.wzsfgb')" prop="isclosed">
        <el-radio-group v-model="baseform.isclosed">
          <el-radio label="1">{{ $t('shi') }}</el-radio>
          <el-radio label="0">{{ $t('fou') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseform: {
        sitename: '',
        service_url: '',
        notice: '',
        notice_en: '',
        notice_es: '',
        notice_fr: '',
        notice_it: '',
        notice_ru: '',
        isclosed: '',
        sellurl: '',
        imageurl: ''
      },
      // 添加/编辑数据对象
      addform: {
        imageurl: ''
      },
      dialogvisible: false,
      addrules: {
        imageurl: [{ required: true, message: '请上传图片', trigger: 'blur' }]
      },
      editdialogvisible: false,
      // 设置图片上传的token
      headerobj: {
        Authorization: window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getbase()
  },
  methods: {
    async getbase() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/config/baseconfig')
      if (data) {
        if (data.code === 200) {
          this.baseform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 图片上传成功操作
    upload(res) {
      this.baseform.imageurl = res.data.url
      this.addform.imageurl = res.data.url
      console.log('baseform', this.baseform)
    },
    // 提交
    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.post(
        '/admin/config/baseconfig',
        this.baseform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
          // this.getbase()
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 重置
    reset() {
      this.getbase()
    }
  }
}
</script>

<style lang="less" scoped>
.el-alert {
  margin-top: 15px;
}
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
